import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CourseworkDisclaimer from "../components/CourseworkDisclaimer"

const TemplatePage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>CSCE 420: Competitive Programming</h1>
            {CourseworkDisclaimer()}
            <sub>Or more correctly <em>Problem Solving Strategies</em></sub>
            <p>CSCE 420 is a newer course, focused on Problem Solving Strategies. We just did problem off of <a href="https://open.kattis.com/">Kattis</a> and got graded on them. Every week we had a lab and a leaderboard. It wasn't a competition, but when there is a time limit and a leaderboard it tends to turn into one. Over the course of the semester we attempted about 140 problems of which I successfully completed 120 or so.</p>
            <p>This space is reserved for discussing some of my favorite problems. For obvious reasons I'm not going to publish GitHub. However, I'm not even going to share problem names. I know people look for hints on <em>anything</em> and am not going to help that.</p>
            <p>I will share a strategy I employed several times: precomputing. Kattis grades you on runtime and limits you to some small file size (16KiB?) of bytes. However, there are a handful of problems that have an input space smaller than that. In these cases it may be faster to pre-compute results.</p>
            <sub><em>Look: a thing I said I wouldn't do:</em></sub>
            <p><a href="https://open.kattis.com/problems/luckynumber">Lucky Number</a> has an input space of 1000 but an output space of only 26 elements (zero occurs many times). This can be solved very efficiently via a case statement.</p>
            <p>A extension of this is to use raw strings to embed an array of compressed binary data in the maximally efficient manner. This allows you to tune very very close to the file size limit in the case of say an input space of 10000 integers with an output in the range 0-255. A word of warning if you go down the raw string route: you editor may do funny things like replacing line endings. A simple `\n` conversion to `\r\n` will change the position of everything in the array after the line ending. You have been warned.</p>
            {FooterBar()}
        </div>
    )
}

export default TemplatePage